import React, { useState } from 'react';
import OpenAI from 'openai';

// Debug the environment variable (remove in production)
console.log('API Key exists:', !!process.env.REACT_APP_OPENAI_API_KEY);

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

const parseEnergizerContent = (content) => {
    const sections = content.split('\n\n');
    const facilitatorNotesSection = sections.find(s => s.toLowerCase().includes('facilitator notes:'));
    const facilitatorNotes = facilitatorNotesSection
        ?.split('FACILITATOR NOTES:')[1]
        ?.trim()
        // First split by new lines that start with a bullet or hyphen
        ?.split(/\n\s*[-•]\s+/)
        ?.filter(Boolean)
        ?.map(note => {
            // Remove any leading hyphens or bullets while preserving in-text hyphens
            return note.replace(/^[-•]\s+/, '').trim();
        });

    return {
        title: sections[0].replace(/^###\s*/, ''),
        timeFrame: sections.find(s => s.toLowerCase().includes('time frame:'))?.split('TIME FRAME:')[1]?.split('GROUP SIZE:')[0]?.trim() || '5-10 min',
        groupSize: sections.find(s => s.toLowerCase().includes('group size:'))?.split('GROUP SIZE:')[1]?.split('MATERIALS:')[0]?.trim() || 'Any',
        materials: sections.find(s => s.toLowerCase().includes('materials:'))?.split('MATERIALS:')[1]?.trim() || 'None',
        purpose: sections.find(s => s.toLowerCase().includes('purpose:'))?.split('PURPOSE:')[1]?.trim(),
        steps: sections.find(s => s.toLowerCase().includes('steps:'))?.split('STEPS:')[1]?.trim(),
        facilitatorNotes: facilitatorNotes || []
    };
};

// Helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const getEnergizerEmoji = (title = '', purpose = '') => {
    const content = (title + ' ' + purpose).toLowerCase();
    
    const emojiMap = {
        // Investigation/Mystery themes
        'mystery': '🕵️',
        'detective': '🕵️',
        'search': '🔎',
        'find': '🔍',
        'discover': '💡',
        'explore': '🗺️',
        
        // Physical items
        'sock': '🧦',
        'box': '📦',
        'ball': '⚾',
        'paper': '📝',
        'pen': '✒️',
        'card': '🃏',
        'book': '📚',
        'chair': '💺',
        'phone': '📱',
        'clock': '⏰',
        'time': '⌛',
        
        // Activities
        'dance': '💃',
        'music': '🎵',
        'song': '🎼',
        'sound': '🔊',
        'draw': '🎨',
        'paint': '🖌️',
        'write': '✍️',
        'speak': '🗣️',
        'talk': '💭',
        'listen': '👂',
        'move': '🏃',
        'jump': '⭐',
        'run': '🏃‍♂️',
        'walk': '🚶',
        'exercise': '💪',
        'stretch': '🧘',
        'yoga': '🧘‍️',
        'clap': '👏',
        'hand': '✋',
        'build': '🏗️',
        'create': '🎨',
        'perform': '🎭',
        'act': '🎬',
        'play': '🎲',
        'game': '🎮',
        
        // Group/Team concepts
        'team': '🤝',
        'group': '👥',
        'partner': '🤼',
        'pair': '👥',
        'together': '🤗',
        'collaborate': '🤝',
        'network': '🕸️',
        
        // Mental activities
        'think': '🤔',
        'brain': '🧠',
        'mind': '🧠',
        'puzzle': '🧩',
        'solve': '💭',
        'creative': '💫',
        'imagine': '🌈',
        'dream': '💭',
        
        // Communication
        'story': '📖',
        'translate': '💬',
        'language': '🗣️',
        'speak': '📢',
        'voice': '🗣️',
        'message': '💌',
        'alien': '👽',
        'signal': '📡',
        
        // Emotions/States
        'happy': '😊',
        'laugh': '😄',
        'funny': '😆',
        'smile': '😊',
        'energy': '⚡',
        'calm': '😌',
        'relax': '😎',
        'focus': '🎯',
        'zen': '🧘',
        
        // Nature elements
        'water': '💧',
        'fire': '🔥',
        'earth': '🌍',
        'air': '🌬️',
        'wind': '🌪️',
        'plant': '🌱',
        'grow': '🌱',
        'tree': '🌳',
        'flower': '🌸',
        
        // Abstract concepts
        'magic': '✨',
        'transform': '🦋',
        'change': '🔄',
        'journey': '🗺️',
        'path': '🛣️',
        'bridge': '🌉',
        'connect': '🔗',
        'link': '⛓️',
        'balance': '🔄',
        'harmony': '🔄',
        'cycle': '🔄',
        'infinity': '♾️',
        'random': '🎲',
        'chance': '🎯',
        'target': '🎯',
        'goal': '🥅',
        'win': '🏆',
        'success': '🌟',
        'challenge': '🏋️',
        'quest': '🗺️',
        'adventure': '🗺️',
        'curiosity': '🤔'
    };

    // Find the first matching keyword and return its emoji
    const match = Object.keys(emojiMap).find(keyword => content.includes(keyword));
    return match ? emojiMap[match] : '⚡'; // Default to ⚡ if no specific match
};

// Helper function to convert markdown-style bold to JSX
const formatBoldText = (text) => {
    return text.split(/\*\*/).map((part, index) => 
        index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
};

export default function CheckInGenerator() {
    // State for each column, initialized with first option selected
    const [selectedType, setSelectedType] = useState('check-in');
    const [selectedMood, setSelectedMood] = useState('curious');
    const [selectedComplexity, setSelectedComplexity] = useState('simple');

    // New state for OpenAI integration
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [generatedQuestion, setGeneratedQuestion] = useState(null);

    // Handler functions to ensure only one selection per column
    const handleTypeChange = (type) => {
        setSelectedType(type);
        setGeneratedQuestion(null);
    };

    const handleMoodChange = (mood) => {
        setSelectedMood(mood);
    };

    const handleComplexityChange = (complexity) => {
        setSelectedComplexity(complexity);
    };

    // New handler for generating questions
    const generateQuestion = async () => {
        setIsLoading(true);
        setError(null);

        try {
            let systemPrompt;
            let userPrompt;

            if (selectedType === 'energizer') {
                systemPrompt = `You are an expert facilitator who creates concise, creative, and unique team exercises. 
                                Your exercises should be:
                                - Fun and memorable
                                - Easy to understand
                                - Adaptable to different group dynamics
                                - Focused on team building and engagement
                                - Original and not repetitive
                                - Inspired by Brené Brown’s principles of vulnerability and psychological safety
                                - Aligned with Tuckman's stages of team development`;
                
                userPrompt = `Generate a concise, highly creative ${selectedComplexity} group energizer exercise with a ${selectedMood} tone.
                             The exercise should be unexpected and novel while remaining practical.
                             Ensure the steps are clear, brief, and the purpose is meaningful for team development.
                             Incorporate elements of psychological safety and vulnerability to enhance team dynamics.
                             
                             Format the response exactly as follows:
                             
                             ### [Creative, Engaging Title]
                             
                             TIME FRAME: [Duration]
                             GROUP SIZE: [Size range]
                             MATERIALS: [Required items or "None"]
                             
                             PURPOSE: [Brief description of benefits and goals]
                             
                             STEPS: [Clear, numbered steps with specific details, each step should be one sentence]
                             
                             FACILITATOR NOTES: 
                             - [Practical tips for successful facilitation]
                             - [Group dynamic considerations]
                             - [Adaptation suggestions]`;
            } else {
                systemPrompt = `You are an expert in crafting diverse and engaging discussion questions that:
                                - Spark meaningful conversations
                                - Encourage authentic sharing
                                - Avoid clichés and overused prompts
                                - Match the specified tone perfectly
                                - Are unique and memorable
                                - Cover a wide range of topics
                                - Inspired by Brené Brown’s principles of vulnerability and psychological safety
                                - Aligned with Tuckman's stages of team development`;
                
                userPrompt = `Generate a diverse, one-sentence ${selectedComplexity} ${selectedType} question with a ${selectedMood} tone.
                             The question should be unique, brief, and avoid common prompts.
                             It should encourage genuine sharing while being appropriate for a professional setting.
                             Incorporate elements of psychological safety and vulnerability to enhance team dynamics.`;
            }

            const completion = await openai.chat.completions.create({
                messages: [
                    {
                        role: "system",
                        content: systemPrompt
                    },
                    {
                        role: "user",
                        content: userPrompt
                    }
                ],
                model: "gpt-4-turbo-preview",
                temperature: 1.0,
                top_p: 0.95,
                frequency_penalty: 0.8,  // Further increase to reduce repetition
                presence_penalty: 0.8,   // Further increase to encourage novelty
            });

            let response = completion.choices[0].message.content;
            
            if (selectedType === 'energizer') {
                const parsedContent = parseEnergizerContent(response);
                setGeneratedQuestion(parsedContent);
            } else {
                response = response.replace(/^["'"″""]+|["'"″""]+$/g, '');
                setGeneratedQuestion(response);
            }
        } catch (err) {
            setError('Failed to generate content. Please try again.');
            console.error('Error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full max-w-5xl mx-auto space-y-4 md:space-y-6 px-0 pt-2">
            {/* Output Area */}
            <div className="bg-white rounded-3xl shadow-lg py-4 px-3 md:py-12 md:px-16">
                {!generatedQuestion ? (
                    <h2 className="text-2xl md:text-4xl font-bold text-center leading-tight">
                        Click 'Generate' to get your {
                            selectedType === 'check-in' ? 'check-in question' :
                            selectedType === 'check-out' ? 'check-out question' :
                            'team exercise'
                        }
                    </h2>
                ) : selectedType === 'energizer' && typeof generatedQuestion === 'object' ? (
                    <div className="space-y-6 md:space-y-8">
                        <h2 className="text-2xl md:text-4xl font-bold">
                            {generatedQuestion.title} {getEnergizerEmoji(generatedQuestion.title, generatedQuestion.purpose)}
                        </h2>
                        
                        <div className="bg-gray-100 p-4 md:p-6 rounded-xl">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                                <div>
                                    <p className="font-semibold">Time Frame:</p>
                                    <p>{generatedQuestion.timeFrame}</p>
                                </div>
                                <div>
                                    <p className="font-semibold">Group Size:</p>
                                    <p>{generatedQuestion.groupSize}</p>
                                </div>
                                <div>
                                    <p className="font-semibold">Materials:</p>
                                    <p>{generatedQuestion.materials}</p>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div>
                                <p className="font-semibold text-xl mb-2">Purpose</p>
                                <p>{generatedQuestion.purpose}</p>
                            </div>
                            <div className="space-y-6">
                                {generatedQuestion.steps.split(/\d+\./).filter(Boolean).map((step, index) => (
                                    <div key={index}>
                                        <p className="font-semibold text-xl mb-2">Step {index + 1}</p>
                                        <p>{formatBoldText(step.trim())}</p>
                                    </div>
                                ))}
                            </div>
                            {generatedQuestion.facilitatorNotes && (
                                <div>
                                    <p className="font-semibold text-xl mb-2">Facilitator Notes</p>
                                    <ul className="list-disc pl-5 space-y-2">
                                        {generatedQuestion.facilitatorNotes.map((note, index) => (
                                            <li key={index}>{formatBoldText(note)}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <h2 className="text-2xl md:text-4xl font-bold text-center">
                        "{generatedQuestion}"
                    </h2>
                )}
            </div>

            {/* Controls Area */}
            <div className="bg-white rounded-3xl shadow-lg p-4 md:p-12">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 mb-8 md:mb-12">
                    {/* Type Column */}
                    <div>
                        <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Type</p>
                        <div className="space-y-2">
                            <label className="flex items-center justify-between text-xl">
                                <span>🔍 Check-In</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedType === 'check-in'}
                                        onChange={() => handleTypeChange('check-in')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                            <label className="flex items-center justify-between text-xl">
                                <span>⚡️ Energizer</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedType === 'energizer'}
                                        onChange={() => handleTypeChange('energizer')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                            <label className="flex items-center justify-between text-xl">
                                <span>🏁 Check-Out</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedType === 'check-out'}
                                        onChange={() => handleTypeChange('check-out')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>

                    {/* Mood Column */}
                    <div>
                        <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Mood</p>
                        <div className="space-y-2">
                            <label className="flex items-center justify-between text-xl">
                                <span>🤔 Curious</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedMood === 'curious'}
                                        onChange={() => handleMoodChange('curious')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                            <label className="flex items-center justify-between text-xl">
                                <span>👤 Personal</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedMood === 'personal'}
                                        onChange={() => handleMoodChange('personal')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                            <label className="flex items-center justify-between text-xl">
                                <span>🌀 Weird</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedMood === 'weird'}
                                        onChange={() => handleMoodChange('weird')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>

                    {/* Complexity Column */}
                    <div>
                        <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Complexity</p>
                        <div className="space-y-2">
                            <label className="flex items-center justify-between text-xl">
                                <span>✅ Simple</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedComplexity === 'simple'}
                                        onChange={() => handleComplexityChange('simple')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                            <label className="flex items-center justify-between text-xl">
                                <span>🔸 Moderate</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedComplexity === 'moderate'}
                                        onChange={() => handleComplexityChange('moderate')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                            <label className="flex items-center justify-between text-xl">
                                <span>🧠 Deep</span>
                                <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out rounded-full bg-gray-200">
                                    <input 
                                        type="checkbox" 
                                        checked={selectedComplexity === 'deep'}
                                        onChange={() => handleComplexityChange('deep')}
                                        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer peer checked:translate-x-full checked:bg-black"
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                {error && (
                    <div className="text-red-500 text-center mb-4">
                        {error}
                    </div>
                )}

                <button 
                    onClick={generateQuestion}
                    disabled={isLoading}
                    className={`w-full bg-green-500 text-white text-lg md:text-xl font-semibold py-3 md:py-4 rounded-lg ${
                        isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'
                    }`}
                >
                    {isLoading ? 'Generating...' : 'Generate'}
                </button>
            </div>
        </div>
    );
}