import React, { useState } from 'react';
import { Link, Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import CheckInGenerator from './components/checkingenerator';
import About from './components/about';
import Contact from './components/contact';
import HowTo from './components/howto';

export default function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <Router>
            <div className="min-h-screen flex flex-col">
                {/* Header */}
                <header className="w-full bg-white shadow-sm">
                    <div className="container mx-auto flex items-center justify-between py-4 px-6">
                        <Link to="/" className="text-2xl font-bold text-gray-800">⚡ Check-in Questions</Link>
                        
                        {/* Desktop Navigation */}
                        <nav className="hidden md:block">
                            <ul className="flex space-x-6">
                                <li><Link to="/how-to-use" className="text-gray-600 hover:text-gray-800">How to Use</Link></li>
                                <li><Link to="/about" className="text-gray-600 hover:text-gray-800">About</Link></li>
                                <li><Link to="/contact" className="text-gray-600 hover:text-gray-800">Contact</Link></li>
                            </ul>
                        </nav>

                        {/* Mobile Hamburger Button */}
                        <button 
                            className="md:hidden p-2"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                            aria-label="Toggle menu"
                        >
                            <svg 
                                className="w-6 h-6" 
                                fill="none" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                {isMenuOpen ? (
                                    <path d="M6 18L18 6M6 6l12 12" />
                                ) : (
                                    <path d="M4 6h16M4 12h16M4 18h16" />
                                )}
                            </svg>
                        </button>

                        {/* Mobile Menu */}
                        {isMenuOpen && (
                            <div className="absolute top-16 left-0 right-0 bg-white shadow-lg md:hidden">
                                <nav className="container mx-auto px-6 py-3">
                                    <ul className="space-y-3">
                                    <li>
                                            <Link 
                                                to="/how-to-use" 
                                                className="block text-gray-600 hover:text-gray-800"
                                                onClick={() => setIsMenuOpen(false)}
                                            >
                                                How to Use
                                            </Link>
                                        </li>

                                        <li>
                                            <Link 
                                                to="/about" 
                                                className="block text-gray-600 hover:text-gray-800"
                                                onClick={() => setIsMenuOpen(false)}
                                            >
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link 
                                                to="/contact" 
                                                className="block text-gray-600 hover:text-gray-800"
                                                onClick={() => setIsMenuOpen(false)}
                                            >
                                                Contact
                                            </Link>
                                        </li>


                                    </ul>
                                </nav>
                            </div>
                        )}
                    </div>
                </header>

                {/* Main Content with Gradient - Updated with flex centering */}
                <main className="flex-grow bg-gradient-to-b from-blue-400 from-50% via-blue-300 via-80% to-blue-100 flex items-center justify-center p-2 md:p-8">
                    
                    <Routes>
                        <Route path="/" element={<CheckInGenerator />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/how-to-use" element={<HowTo />} />
                    </Routes>
                </main>

                {/* Footer */}
                <footer className="py-4 text-center text-gray-600 bg-[#020817]">
                    <p className="text-white">Made with ❤️ in Copenhagen 2024</p>
                </footer>
            </div>
        </Router>
    );
}
